import React from 'react'
import Link from 'next/link'
import Script from 'next/script'
import { CONSTS, PAGEURL, APIURL } from '/constants'
import services from '/components/services'
import dayjs from 'dayjs'

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const GA_ENABLED = process.env.NODE_ENV === 'production'

function withAdminConditions(callback) {
  return withPageConditions(callback, null, CONSTS.ADMINS)
}

function withProfileAuth(callback = null) {
  if (!callback) {
    return withPageConditions(
      (_, siteData, profile) => ({
        props: {
          siteData,
          profile
        }
      }),
      null,
      CONSTS.ANY_ROLE
    )
  } else {
    return withPageConditions(callback, null, CONSTS.ANY_ROLE)
  }
}

function withPageCustom(
  page_slug,
  callback,
  pageAllowed,
  rolesAllowed,
  plansAllowed
) {
  async function authWrapper(ctx) {
    const { host } = ctx.req.headers
    const cookie = ctx.req.headers.cookie

    const res2 = await services.get_v2(
      APIURL.API_PAGE_DATA(page_slug),
      {},
      host,
      cookie
    )
    const page = res2.statusCode === 200 ? res2.response : false
    const wrapper = withPageConditions(
      callback,
      pageAllowed,
      rolesAllowed,
      plansAllowed,
      page
    )
    return wrapper(ctx)
  }
  return authWrapper
}

function withPageConditions(
  callback,
  pageAllowed = null,
  rolesAllowed = [],
  plansAllowed = [],
  page = null
) {
  async function authWrapper(ctx) {
    const { host } = ctx.req.headers
    const cookie = ctx.req.headers.cookie
    const res = await services.get_v2(APIURL.API_SITE_DATA, {}, host)
    if (res.statusCode !== 200) {
      return {
        notFound: true
      }
    }
    const siteData = res.response
    if (pageAllowed) {
      if (!siteData[pageAllowed.attribute]) {
        return {
          redirect: {
            destination: pageAllowed.redirect,
            permanent: false
          }
        }
      }
    }

    const profileRes = await services.get_v2(
      APIURL.API_GET_USER_PROFILE,
      {},
      host,
      cookie
    )

    if (
      page?.authentication === undefined ||
      page?.authentication === null ||
      page?.authentication === 'default' ||
      page?.authentication === 'required'
    ) {
      if (
        (!cookie || profileRes.statusCode !== 200) &&
        (siteData.required_authentication ||
          page?.authentication === 'required' ||
          rolesAllowed.length > 0 ||
          plansAllowed.length > 0)
      ) {
        const loginUrl = siteData.login_url || PAGEURL.LOGIN
        return {
          redirect: {
            destination: `${loginUrl}?next=${encodeURIComponent(ctx.req.url)}`,
            permanent: false
          }
        }
      }
    }
    const profile = profileRes.response
    if (
      siteData.is_delinquent &&
      (!profile || profile.userData.role_id === CONSTS.ADVISOR_ROLE)
    ) {
      return {
        redirect: {
          destination: PAGEURL.UNAVAILABLE,
          permanent: false
        }
      }
    }
    return callback(ctx, siteData, profile, page)
  }
  return authWrapper
}

function withAuthConditions(callback, identity_provider, action) {
  async function authWrapper(ctx) {
    const mismatchMap = {
      auth0: {
        login: PAGEURL.OIDC_AUTHENTICATE_LOGIN,
        logout: PAGEURL.OIDC_AUTHENTICATE_LOGOUT
      },
      local: {
        login: PAGEURL.LOCAL_LOGIN,
        logout: PAGEURL.LOCAL_LOGOUT
      },
      local_lazy: {
        login: PAGEURL.MAGIC_LOGIN,
        logout: PAGEURL.MAGIC_LOGOUT
      },
      local_auto: {
        login: PAGEURL.MAGIC_LOGIN,
        logout: PAGEURL.MAGIC_LOGOUT
      },
      local_sso: {
        login: PAGEURL.LOCAL_SSO_LOGIN,
        logout: PAGEURL.LOCAL_SSO_LOGOUT
      },
      magic_link: {
        login: PAGEURL.MAGIC_LOGIN,
        logout: PAGEURL.MAGIC_LOGOUT
      }
    }
    const { host } = ctx.req.headers
    const cookie = ctx.req.headers.cookie
    const res = await services.get_v2(APIURL.API_SITE_DATA, {}, host, cookie)
    if (res.statusCode !== 200) {
      return {
        notFound: true
      }
    }
    const siteData = res.response
    // Note: treat local_lazy as magic link
    const site_identity_provider =
      siteData.identity_provider == 'local_lazy' ||
      siteData.identity_provider == 'local_auto'
        ? 'magic_link'
        : siteData.identity_provider
    if (site_identity_provider !== identity_provider) {
      return {
        redirect: {
          destination: mismatchMap[siteData.identity_provider][action],
          permanent: false
        }
      }
    }
    return callback(ctx, siteData)
  }
  return authWrapper
}

const personalizeUrl = (url, userData) => {
  const whitelist = [
    'first_name',
    'last_name',
    'phone',
    'email',
    'firm_name',
    'slug'
  ]
  const matches = url.match(/{{.+?}}/g)
  if (matches) {
    matches.forEach((m) => {
      const key = m.replace(/{|}/g, '').trim()
      let value = ''
      if (whitelist.includes(key)) {
        value = userData[key] || ''
      }
      url = url.replace(m, encodeURIComponent(value))
    })
  }
  return url
}

const currencyN = (x) => {
  if (!x) {
    return '$0.00'
  }
  return (
    '$' +
    x
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  )
}

const formatN = (x) => {
  if (!x) {
    return '0'
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const percentN = (x) => {
  if (!x) {
    return '0%'
  }
  return x.toFixed(0) + '%'
}

function gtag(data) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(data)
}

function loginEvent() {
  fireEvent('Signin')
}

function registerEvent() {
  fireEvent('Register')
}

function paymentEvent(stripe_plan_id) {
  fireEvent('Paid', null, {
    stripe_plan_id
  })
}

function rcEvent(product_name, product_id) {
  fireEvent(
    'Request Consultation',
    {},
    {
      product_name,
      product_id
    }
  )
}

function fireEvent(eventAction, gaParams = null, mixParams = null) {
  if (GA_ENABLED) {
    const params = {}
    if (gaParams) {
      if (gaParams.eventCategory) {
        params.event_category = gaParams.eventCategory
      }
      if (gaParams.eventLabel) {
        params.event_label = gaParams.eventLabel
      }
      if (gaParams.value) {
        params.value = gaParams.value
      }
    }
    gtag({ event: eventAction, ...params })
  }
  {
    const params = {}
    if (mixParams) {
      if (mixParams.product_name) {
        params['Product Name'] = mixParams.product_name
        params['Product ID'] = mixParams.product_id
      }
    }
    // Mixpanel.track(eventAction, params)
  }
}

function pageView() {
  /*
  if (typeof window !== 'untitled') {
    Mixpanel.track('Page View', {
      'Page Path': window.location.pathname,
      'Page Title': pageTitle
    })
  }
  */
}

const openInNewTab = (href) => {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href
  }).click()
}

const surveyConfig = ({ product_id, questions }) => {
  return {
    name: `Take a quick ${questions.length} question survey`,
    id: product_id,
    random: false,
    timeBeforePopUp: 0, // in seconds
    postUrl: APIURL.BASE_URL,
    questions: questions
      .sort((a, b) => a.order - b.order)
      .map((ques) => ({
        text: ques.question,
        choices: [
          {
            id: 1,
            text: 'Yes'
          },
          {
            id: 2,
            text: 'No'
          }
        ],
        multiple: false,
        id: ques.id,
        required: true
      })),
    ending: {
      text: `Thank your for your answers!`,
      email: false,
      freeSpeech: false
    },
    messages: {
      errorMessage: 'You have to select an option',
      welcomeMessage: '',
      nextMessage: 'Next',
      endMessage: 'End',
      endingMessage: 'Thank you for your time.',
      closeMessage: 'Close'
    }
  }
}

const updateHash = (nextHash) => {
  if (nextHash && nextHash !== 'undefined') {
    window.history.pushState(null, null, '#' + nextHash)
  } else {
    window.history.pushState(null, null, ' ')
  }
  pageView(window.document.title)
}

const SmartLink = (props) => {
  if (props.to[0] === '/') {
    return <Link {...props}>{props.children}</Link>
  } else {
    return (
      <a href={props.to} {...props}>
        {props.children}
      </a>
    )
  }
}

const sortSections = (sections) => {
  const s = {
    employeebenefits: 0,
    marketing: 1,
    'technology-solutions': 2,
    mna: 3,
    'investment-solutions': 4,
    insurancesolutions: 5,
    backoffice: 6,
    compliance: 7,
    lifestyle: 8,
    professionaldevelopment: 9,
    'cpr-investment-solutions': 10
  }
  return sections.sort((a, b) => s[a.slug] - s[b.slug])
}

const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error)
            return
          }

          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

const trimFontSpaces = (theme) => {
  let headerFontName, bodyFontName
  if (theme.header_font_family) {
    headerFontName = theme.header_font_family.name.replace(/ /g, '+')
  }
  if (theme.body_font_family) {
    bodyFontName = theme.body_font_family.name.replace(/ /g, '+')
  }

  return { headerFontName, bodyFontName }
}

const configureHead = (siteData) => {
  const scripts = []
  if (siteData.slug === 'smallbusinessbenefits') {
    scripts.push(
      <meta
        key="fb"
        name="facebook-domain-verification"
        content="k5vpmif3xzqpk8wu2ufkbz0nx9gf1i"
      />
    )
    scripts.push(
      <meta
        key="google"
        name="google-site-verification"
        content="jBTWwAFxQH1HzdVXFHqb3-d1qV02vafrpnclwBYvsaE"
      />
    )
  } else if (siteData.slug === 'earthlink') {
    scripts.push(
      <meta name="fo-verify" content="d83e0420-0515-4f7e-b7fa-2903d4c567b6" />
    )
  }
  if (siteData.gtm_id) {
    const url = `https://www.googletagmanager.com/gtag/js?id=${siteData.gtm_id}`
    const script = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${siteData.gtm_id}');
    `
    scripts.push(<script key="gtm-1" async src={url} />)
    scripts.push(
      <script
        id="gtm-2"
        key="gtm-2"
        dangerouslySetInnerHTML={{ __html: script }}
      />
    )
  }
  if (siteData.intercom_id) {
    scripts.push(
      <script
        key={3}
        dangerouslySetInnerHTML={{
          __html: `
            window.intercomSettings = {
            app_id: "${siteData.intercom_id}"
            };
            function loadIntercom(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${siteData.intercom_id}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}}
            if (window) {
            window.setTimeout(loadIntercom, 0)
            }
          `
        }}
      />
    )
  } else if (process.env.NEXT_PUBLIC_INTERCOM_SUPPORT_ID) {
    const APP_ID = process.env.NEXT_PUBLIC_INTERCOM_SUPPORT_ID
    scripts.push(
      <script
        key={3}
        dangerouslySetInnerHTML={{
          __html: `
            window.intercomSettings = {
              app_id: "${APP_ID}"
            };
            function loadIntercom(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${siteData.intercom_id}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}}
          `
        }}
      />
    )
  }
  if (!siteData.robot_index) {
    scripts.push(<meta key="robots" name="robots" content="noindex" />)
  }
  if (siteData.theme) {
    scripts.push(
      <style
        key={5}
        dangerouslySetInnerHTML={{
          __html: `
                .primary {
                background-color: ${siteData.theme.primary_color} !important;
                border-color: ${siteData.theme.primary_color} !important;
                }
                .primary-bb { border-color-bottom: ${siteData.theme.primary_color} !important; }
                .secondary { background-color: ${siteData.theme.secondary_color} !important; }
                .secondary-bb { border-bottom-color: ${siteData.theme.secondary_color} !important; }
                .tertiary { background-color: ${siteData.theme.tertiary_color} !important; }
                `
        }}
      />
    )
  }

  if (siteData?.favicon_media_center?.media_thumbnail) {
    scripts.push(
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href={siteData?.favicon_media_center?.media_thumbnail}
      />
    )
  } else if (siteData.favicon_url_32) {
    scripts.push(
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href={siteData.favicon_url_32}
      />
    )
  }
  if (process.env.NEXT_PUBLIC_REWARDFUL_ID) {
    scripts.push(
      <script
        key="rewardful1"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');
          `
        }}
      />
    )
    scripts.push(
      <script
        key="rewardful2"
        async
        src="https://r.wdfl.co/rw.js"
        data-rewardful={process.env.NEXT_PUBLIC_REWARDFUL_ID}
      />
    )
  }

  return scripts
}

const checkIsWhiteLabel = (host) => {
  const hosts = [
    'staging.chalicenetwork',
    'staging-next.chalicenetwork',
    'home.chalicenetwork',
    'staging-home.chalicenetwork',
    'next.chalicenetwork',
    'www.chalicenetwork',
    'chalicefintech.local',
    'localhost'
  ]
  const found = hosts.find((x) => host.startsWith(x))
  if (found && found !== (null || undefined)) {
    return false
  }
  return true
}

const isServer = () => {
  if (typeof window === 'undefined') return true
  return false
}

function hasSupport() {
  return (
    typeof Intercom != 'undefined' &&
    process.env.NEXT_PUBLIC_INTERCOM_SUPPORT_ID
  )
}

function showSupport(content) {
  if (content) {
    window.Intercom('showNewMessage', content)
  } else {
    window.Intercom('show')
  }
}

function loadSupport() {
  if (hasSupport()) {
    window.loadIntercom()
    /*
    window.Intercom('boot', {
      app_id: process.env.NEXT_PUBLIC_INTERCOM_SUPPORT_ID
    })
    */
  }
  return () => {
    if (hasSupport()) {
      window.Intercom('shutdown')
    }
  }
}

function calcExpires(expires) {
  if (!expires) {
    return null
  }
  let diff = dayjs(expires).diff(dayjs(), 'seconds')
  let days = null,
    hours = null,
    minutes = null,
    seconds = null
  let s = ''
  if (diff > 24 * 60 * 60) {
    days = Math.floor(diff / (24 * 60 * 60))
    diff = diff % (24 * 60 * 60)
    s += days + 'D, '
  }
  if (diff > 60 * 60) {
    hours = Math.floor(diff / (60 * 60))
    diff = diff % (60 * 60)
    s += hours + 'H, '
  }
  if (diff > 60) {
    minutes = Math.floor(diff / 60)
    seconds = diff % 60
    s += minutes + 'M and ' + seconds + 'S'
  }
  return s
}

export {
  currencyN,
  percentN,
  formatN,
  pageView,
  openInNewTab,
  surveyConfig,
  loginEvent,
  registerEvent,
  rcEvent,
  paymentEvent,
  personalizeUrl,
  updateHash,
  SmartLink,
  sortSections,
  retry,
  trimFontSpaces,
  configureHead,
  checkIsWhiteLabel,
  isServer,
  hasSupport,
  showSupport,
  loadSupport,
  calcExpires,
  withPageConditions,
  withAdminConditions,
  withProfileAuth,
  withAuthConditions,
  withPageCustom
}
