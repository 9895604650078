const PAGEURLS = {
  MARKETING_NEW_ADVISOR: 'https://www.chalicefn.com/marketplace/',
  HOME: '/',
  HOME2: '/home/',
  HOME2_: '/home',
  NEWS: '/news/',
  NEWS_: '/news',
  EVENTS: '/events/',
  EVENTS_: '/events',
  PRESS: '/press/',
  PRESS_: '/press',
  ABOUT_US: '/about/',
  ABOUT_US_: '/about',
  LOGIN: '/login/',
  LOGIN_: '/login',
  LOGOUT: '/logout/',
  LOGOUT_: '/logout',
  SIGNUP: '/signup/',
  SIGNUP_: '/signup',
  CONTACT_US: '/contact/',
  CONTACT_US_: '/contact',
  ABOUT: '/about/',
  ABOUT_: '/about',
  PRICING: '/pricing/',
  PRICING_: '/pricing',
  MARKETPLACE: '/marketplace/',
  MARKETPLACE_: '/marketplace',
  PRODUCT: (slug) => `/marketplace/${slug}/`,
  COLLECTION: (slug) => `/collection/${slug}/`,
  UNAVAILABLE: '/unavailable',
  ONBOARDING: '/signup/index/',

  PORTAL: '/partner/portal/',
  PORTAL_DETAILS: (opportunity_id) => `/partner/portal/?id=${opportunity_id}`,
  PORTAL_: '/partner/portal',

  PARTNER_COMPANY: '/partner/company/',
  PARTNER_BILLING: '/partner/billing/',
  PARTNER_PRODUCT_MGMT: '/partner/product/',
  PARTNER_PRODUCT_REDIRECT: '/partner/product/redirect/',
  PARTNER_TEAM: '/partner/team/',
  PARTNER_NOTIFICATIONS: '/partner/notifications/',
  PARTNER_MEDIA: '/partner/media/',

  SIGNUP_REGISTER: '/signup/register/',
  SIGNUP_REGISTER_: '/signup/register',
  SIGNUP_INFO: '/signup/info/',
  SIGNUP_FINISHED: '/signup/finished/',
  SIGNUP_PLANS: '/signup/plans',
  SIGNUP_PAYMENT: '/signup/payment',
  SIGNUP_SUCCESS: (tier) => `/signup/success/${tier}/`,
  SIGNUP_REQUEST_CONSULTATION: '/signup/req-consultation',

  SIGNUP_ADD_ONS: '/signup/add-ons/',

  CYBER_INSURANCE: '/cyber/insurance/',
  CYBER_INSURANCE_: '/cyber/insurance',
  CYBER_INSURANCE_BIND: (uuid) => `/cyber/insurance/${uuid}`,
  CYBER_INSURANCE_CONFIRM: (uuid) => `/cyber/insurance/confirm/${uuid}`,

  FA_MAG: '/famag/',
  FA_MAG_: '/famag',

  TERMS_CONDITIONS: '/terms-of-service/',
  TERMS_CONDITIONS_: '/terms-of-service',

  PRIVACY_POLICY: '/privacy-policy/',
  PRIVACY_POLICY_: '/privacy-policy',

  ADMIN: '/admin/',
  DASHBOARD: '/dashboard',
  MESSENGER: '/messenger',
  SUBSCRIBE: '/subscribe',
  THANK_YOU: '/thankyou',
  CONSULTATION: '/consultation',
  INTEGRATIONS: '/integrations',
  MY_PROFILE: '/my-profile',
  PROFILE: '/profile',
  PROOF_OF_MEMBESHIP: '/profile/proof-of-membership',
  BILLING_SUBSCRIPTION: '/profile/billing/subscription/',
  BILLING_PAYMENT_HISTORY: '/profile/billing/payment-history/',
  BILLING_PAYMENT_METHOD: '/profile/billing/payment-method/',
  ORG: '/profile/organization',
  ADMIN_PROFILE: '/admin/profile',
  ROI_CALCULATOR_: `/roi-calculator/`,
  ROI_CALCULATOR: (question) => `/roi-calculator/${question}/`,
  ROI_CALCULATOR_EMBED: (question) => `/embed/roi-calculator/${question}/`,
  SAVINGS_CALCULATOR: '/savings-calculator/',
  SAVINGS_CALCULATOR_EMBED: '/savings-calculator/embed/',

  CHANGE_EMAIL: '/profile/change/email',
  CHANGE_PASSWORD: '/profile/change/password',
  ADMIN_CHANGE_EMAIL: '/admin/change-email',
  SNAPSHOT: '/snapshot/',
  ORGANIZATION: '/organization/',
  PRODUCT_DETAILS: '/marketplace/',
  ADMIN_CHANGE_PASSWORD: '/admin/change-password',
  EDIT_CONSULTATION: '/admin/edit-consultation',
  UPDATE_SURVEY: '/admin/edit-survey/',
  SURVEY_MGMT: '/admin/survey-management/',
  NEW_SURVEY: '/admin/add-survey-question',
  PRODUCT_DETAILS_REDIRECT: (product_slug, cta_id) =>
    `/marketplace/${product_slug}/redirect/${cta_id}`,
  REDIRECT: (url) => `/redirect/${url}`,

  NEW_MEMBERSHIP_PLAN: '/admin/membership-plan-add',
  UPDATE_MEMBERSHIP_PLAN: '/admin/edit-membership-plan/',
  MEMBERSHIP_PLAN_MGNT: '/admin/membership-plan-management',

  CALC_LEAD_MGMT: '/admin/calc-lead',
  CALC_LEAD_ADD: '/admin/calc-lead-add/',
  CALC_LEAD_EDIT: '/admin/calc-lead-edit/',

  ADVISOR_PAYMENT_HISTORY: '/payment-history',
  ACTIVE_SUBSCRIPTION: '/active-subscription',
  BUY_MEMBERSHIP_PLAN: '/membership',
  PAYMENT_SUCCESS: '/payment-complete',
  MEMB_PRODU_ASS: '/admin/membership-product-association',
  ERROR_FOB: '/error',
  SSO_MGNT: '/admin/sso-management',
  NEW_SSO: '/admin/add-sso',
  RSS_FEED_MGNT: '/admin/rss-management',
  NEW_RSS_FEED: '/admin/add-rss-feed',
  UPDATE_RSS_FEED: '/admin/edit-rss-feed/',
  UPDATE_SSO: '/admin/edit-sso/',
  ADMIN_PAYMENT_HISTORY: '/admin/advisors-payment-hostory',
  PAGE_MGNT: '/admin/page-management',
  NEW_PAGE: '/admin/new-page',
  UPDATE_PAGE: '/admin/edit-page/',
  RE_ARRANGE_PAGE_ORDER: '/admin/re-arrange-page-order',
  WIDGET_MGNT: '/admin/widget-management',
  NEW_WIDGET: '/admin/new-widget',
  UPDATE_WIDGET: '/admin/edit-widget/',

  ERROR_500: '/error500',
  FORGOT_PASSWORD: '/profile/forgot/',
  OTP_VERIFICATION: '/otp-verification/',
  VERIFY_SIGNUP_TOKEN: '/verifyToken/',
  RESET_PASSWORD: '/reset-password/',
  SET_PASSWORD: '/set-password/',
  ACCOUNT_SETUP: '/profile/setup/',
  REFER_FRIEND: '/refer/friend/',
  AUTH_AUTHENTICATE: '/oauth/v2/authenticate',

  AUTH_2_0_AUTHENTICATE: '/signin/oauth/oauthchooseaccount',
  OIDC_AUTHENTICATE: '/idp/',
  OIDC_AUTHENTICATE_LOGIN: '/idp/login',
  OIDC_AUTHENTICATE_SIGNUP: '/idp/signup',
  OIDC_AUTHENTICATE_LOGOUT: '/idp/logout',

  LOCAL_LOGIN: '/auth/login/',
  LOCAL_LOGOUT: '/auth/logout/',

  MAGIC_LOGIN: '/auth/magic/login/',
  MAGIC_LOGIN_PIN: '/auth/magic/pin/',
  MAGIC_LOGOUT: '/auth/magic/logout/',

  LOCAL_SSO_LOGIN: '/auth/sso/login/',
  LOCAL_SSO_LOGOUT: '/auth/sso/logout/',

  SAML_IDP: '/Saml/idp',
  SIDEBAR_MENU: '/solution/',
  PAGES_MENU: '/pages/',
  ROLE_MGNT: '/admin/role-management',
  NEW_ROLE: '/admin/add-new-role',
  UPDATE_ROLE: '/admin/edit-role/',
  REDTAIL_CRM_WIDGET: '/admin/custom-widget',
  REDTAIL_CRM_NEW_WIDGET: '/admin/custom-new-widget',
  REDTAIL_CRM_EDIT_WIDGET: '/admin/custom-update-widget/',

  CUSTOM_WIDGET: '/admin/custom-widget',
  CUSTOM_NEW_WIDGET: '/admin/custom-new-widget',
  CUSTOM_EDIT_WIDGET: '/admin/custom-update-widget/',

  EVENT_MGNT: '/admin/event-management',
  NEW_EVENT: '/admin/new-event',
  UPDATE_EVENT: '/admin/edit-event/',

  REDTAIL_DASHBOARD: '/redtail/dashboard',

  QUICKBOOKS_LOGIN: '/quickbooks/authenticate',
  SAVED_PAYMENT_METHOD: '/saved-payment-method',

  ORION_LOGIN: '/orion/authenticate',

  ADMIN_GETTING_STARTED: '/admin/start',
  ADMIN_DASHBOARD: '/admin/dashboard',

  USER_MGMT: '/admin/user/',
  USER_INVITE: '/admin/user/invite/',
  LEAD_MGMT: '/admin/lead/',
  NEW_USER: '/admin/user/add/',
  USER_EDIT: (id) => `/admin/user/${id}/`,
  USER_HISTORY: (id) => `/admin/user/${id}/history/`,

  ORGANIZATION_MGMT: '/admin/organization/',
  UPDATE_ORGANIZATION: (id) => `/admin/organization/${id}/`,
  NEW_ORGANIZATION: '/admin/organization/add/',

  CONSULTATION_MGMT: '/admin/report/',
  NEW_CONSULTATION: '/admin/report/add/',

  VENDOR_MGNT: '/admin/vendor/',
  UPDATE_VENDOR: (id) => `/admin/vendor/${id}/`,
  NEW_VENDOR: '/admin/vendor/add/',

  EXPLORE: '/admin/explore',

  NEW_PRODUCT_DETAILS: `/admin/product/add/details/`,
  UPDATE_PRODUCT_DETAILS: (id) => `/admin/product/${id}/details/`,
  UPDATE_PRODUCT_DESCRIPTION: (id) => `/admin/product/${id}/description/`,
  UPDATE_PRODUCT_ACTIONS: (id) => `/admin/product/${id}/action/`,
  UPDATE_PRODUCT_ASSOCIATION: (id) => `/admin/product/${id}/association/`,

  INVENTORY_MGNT: '/admin/inventory/',
  INVENTORY_EDIT: (id) => `/admin/inventory/${id}/`,

  PRODUCT_MGNT: '/admin/product/',
  UPDATE_PRODUCT: (id) => `/admin/product/${id}/details/`,
  REVIEW_MGMT: (id) => `/admin/product/${id}/review/`,
  NEW_REVIEW: (id) => `/admin/product/${id}/review/add/`,
  UPDATE_REVIEW: (product_id, review_id) =>
    `/admin/product/${product_id}/review/${review_id}/`,
  FAQ_MGMT: (id) => `/admin/product/${id}/faq/`,
  NEW_FAQ: (id) => `/admin/product/${id}/faq/add/`,
  UPDATE_FAQ: (product_id, faq_id) =>
    `/admin/product/${product_id}/faq/${faq_id}/`,
  PRODUCT_CAROUSEL_MEDIA: (id) => `/admin/product/${id}/carouselmedia/`,
  PRODUCT_METADATA: (id) => `/admin/product/${id}/metadata/`,
  PRODUCT_STATUS: (id) => `/admin/product/${id}/status/`,

  PRODUCT_TAGS_MGNT: '/admin/category/',
  UPDATE_PRODUCT_TAGS: (id) => `/admin/category/${id}/`,
  NEW_PRODUCT_TAGS: '/admin/category/add/',

  ADMIN_CALCULATOR: '/admin/calculator/',
  ADMIN_CALCULATOR_DETAILS: (id) => `/admin/calculator/${id}/`,

  PRODUCT_COLLECTION_MGNT: '/admin/collection/',
  UPDATE_PRODUCT_COLLECTION: (id) => `/admin/collection/${id}/`,
  NEW_PRODUCT_COLLECTION: '/admin/collection/add/',

  AD_MGNT: '/admin/ad/',
  UDPATE_AD: (id) => `/admin/ad/${id}/`,
  NEW_AD: '/admin/ad/add',

  API_KEY: '/admin/developers/api-keys',

  ADMIN_ENTERPRISE: '/admin/enterprise/',
  NEW_ENTERPRISE: '/admin/enterprise/add/details/',
  ENTERPRISE_DETAILS: (enterprise_id) =>
    `/admin/enterprise/${enterprise_id}/details/`,
  ENTERPRISE_LINKS: (enterprise_id) =>
    `/admin/enterprise/${enterprise_id}/links/`,
  ENTERPRISE_ASSOCIATION: (enterprise_id) =>
    `/admin/enterprise/${enterprise_id}/association/`,

  COUPON_MGMT: '/admin/coupon/',
  UPDATE_COUPON: (id) => `/admin/coupon/${id}/`,
  NEW_COUPON: '/admin/coupon/add/',

  ADMIN_SETTINGS: '/admin/settings/',
  ADMIN_SETTINGS_DETAIL: '/admin/settings/details/',
  ADMIN_SETTINGS_NOTIFICATIONS: '/admin/settings/notifications/',
  ADMIN_SETTINGS_MENU: '/admin/settings/navigation/',
  ADMIN_SETTINGS_MENU_DETAIL: (menu_id) =>
    `/admin/settings/navigation/${menu_id}/`,
  ADMIN_SETTINGS_LINKS: '/admin/settings/links/',
  ADMIN_SETTINGS_ADVANCED: '/admin/settings/advanced/',
  ADMIN_SETTINGS_ASSOCIATION: '/admin/settings/association/',
  ADMIN_SETTINGS_BILLING: '/admin/settings/billing/',
  ADMIN_SETTINGS_SUBSCRIBE: '/admin/settings/subscribe/',
  ADMIN_SETTINGS_DOMAIN: '/admin/settings/domain/',
  ADMIN_SETTINGS_AUTHENTICATION: '/admin/settings/authentication/',
  ADMIN_SETTINGS_AUTHENTICATION_ADD: '/admin/settings/authentication/add/',
  ADMIN_SETTINGS_AUTHENTICATION_EDIT: (sso_id) =>
    `/admin/settings/authentication/${sso_id}/`,

  ADMIN_SITE_BRANDING: '/admin/site/branding/',
  ADMIN_SITE_PAGE: '/admin/site/page/',
  ADMIN_SITE_MEDIA: '/admin/site/media',
  ADMIN_SITE_MEDIA_ADD: '/admin/site/media/add/',
  ADMIN_SITE_MEDIA_EDIT: (media_id) => `/admin/site/media/${media_id}/`,
  ADMIN_SITE_PAGE_DETAILS: (id) => `/admin/site/page/builder/?page_id=${id}`,
  ADMIN_SITE_BLOG: '/admin/site/blog/',
  ADMIN_SITE_BLOG_DETAILS: (id) => `/admin/site/blog/${id}/`,
  ADMIN_SITE_BLOG_ADD: '/admin/site/blog/add/',

  ADMIN_INTEGRATIONS: '/admin/site/integrations/',

  ADMIN_SITE_POST: '/admin/site/post/',
  ADMIN_SITE_POST_DETAILS: (id) => `/admin/site/post/${id}/`,
  ADMIN_SITE_POST_ADD: '/admin/site/post/add/',

  ADMIN_PAGE_BUILDER: '/admin/site/page/builder/',

  ADMIN_DEVELOPERS_WEBHOOKS: '/admin/developers/webhooks/',
  ADMIN_DEVELOPERS_WEBHOOKS_ADD: '/admin/developers/webhooks/add/',
  ADMIN_DEVELOPERS_WEBHOOKS_EDIT: (webhook_id) =>
    `/admin/developers/webhooks/${webhook_id}/`,
  ADMIN_DEVELOPERS_APIKEYS: '/admin/developers/api-keys/',
  ADMIN_DEVELOPERS_APIKEYS_ADD: '/admin/developers/api-keys/add/',
  ADMIN_DEVELOPERS_EVENTS: '/admin/developers/events/',
  ADMIN_DEVELOPERS_EVENTS_VIEW: (id) => `/admin/developers/events/${id}/`,

  PLAN_MGNT: '/admin/plan/',
  ADD_PLAN: '/admin/plan/add/',
  ENTERPRISE_PLANS: '/admin/plan/edit/',
  UPDATE_PLAN: (plan_id) => `/admin/plan/${plan_id}/edit/`,
  ASSOC_PLAN: (plan_id) => `/admin/plan/${plan_id}/association/`,

  ADMIN_INSIGHTS: '/admin/insight/',
  PROFILE_INTEGRATIONS: '/profile/integrations/',

  REPORT_GENERATING: (report_id) => `/admin/report-generating/${report_id}/`,

  CONNECT_SIGNUP: `/connect/signup/`,
  CONNECT_FINISHED: `/connect/signup/finished/`
}

export default PAGEURLS
